<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button variant="gradient-success" to="/login" v-if="isToken == false">
        Entrar
      </b-button>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        v-if="isToken == true"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user ? user.name : '' }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/logo/sicoob-png.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import axios from "@axios";
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  data(){
    return {
      isToken: false,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    this.verificarToken();
  },
  methods: {
    logout() {
      axios.post("logout", {}).then(() => {
          localStorage.removeItem('userData')
          localStorage.removeItem(["accessToken"]);
          localStorage.clear();

          this.$ability.update(initialAbility)

          this.$router.push({ name: 'login' })
          this.isToken = false;
      })
    },

    verificarToken(){
      var token =  localStorage.getItem('accessToken');
      var user =  localStorage.getItem('userData');
      if(token && user){
        this.isToken = true;
      }
    }
  },
  computed: {
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
}
</script>
